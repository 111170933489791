<template>
  <div class="w-100 h-100" id="map-output-section">
    <div id="map" class="w-100 h-100"></div>
    <div><img class="my-building-image" src="方位画像.png" /></div>
    <setting-btn
      icon-color="text-secondary"
      :show-top-link="true"
    ></setting-btn>
    <modal-block-message
      ref="modalBlockMessage"
      parent-id="map-output-section"
    />
    <modal-input-road-and-entrance
      ref="modalInputRoadAndEntrance"
      v-on:next="nextPage"
    />
  </div>
</template>

<script>
import Map from "ol/Map";
import View from "ol/View";
import { transform } from "ol/proj";
import { getCenter } from "ol/extent";
import Tile from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS";
import { createStringXY } from "ol/coordinate";
import { Fill, Stroke, Circle, Style } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import Vector from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { getArea } from "ol/sphere";
import { Draw, Modify } from "ol/interaction";
import { defaults } from "ol/interaction";
import SettingBtn from "@/views/common/SettingBtn";
import ModalBlockMessage from "@/views/common/ModalBlockMessage";
import ModalInputRoadAndEntrance from "@/views/roadAndEntrance/ModalInputRoadAndEntrance";
import { initSitePlanning } from "@/assets/js/dto/E_site_planning.js";
import {
  cmnIsBlank,
  cmnExRound,
  cmnDecimalFormat,
} from "@/assets/js/common.js";
import { USAGE_NO } from "@/assets/js/const.js";

const WGS84 = "EPSG:4326"; // WGS84緯度経度
const WEB_MERCATOR = "EPSG:3857"; //Web MercatorWeb Mercator

export default {
  name: "OutputSection",
  components: {
    SettingBtn,
    ModalBlockMessage,
    ModalInputRoadAndEntrance,
  },
  data() {
    return {
      project: this.$store.state.params.data,
      appId: null,
      map: null,
      youtoTile: null, // 用途地域タイル
      gisInfos: [], //選択した地番に対して地番逆引き検索で取得した情報をストック（地番逆引き検索のchiban型)
      parcelNoList: [], // 選択or描画地番情報（gisInfos を画面用に変換したリスト）をストック
      drawSource: null,
      draw: null,
      modify: null,
      isDraw: false, // 手書き中
    };
  },
  mounted() {
    document.getElementById("map").innerHTML = "";
    this.$_init();
  },
  watch: {
    parcelNoList: {
      handler: function (newVal) {
        this.$emit("set-parcel-no-list", newVal);
      },
      deep: true,
    },
  },
  methods: {
    /* 初期処理 */
    $_init() {
      // ストアの敷地情報をクリア
      let params = this.$store.state.params;
      if (params.data.site_planning != null)
        params.data.site_planning = initSitePlanning();
      this.$store.commit("setParams", params);
      Promise.all([this.$_getCdsApiKey(), this.$_getGeospaceApiUser()]).then(
        () => {
          this.$_getMaps(this.$store.state.authKey);
          this.$_setData();
        }
      );
    },
    /* 外部API(CDSAPI)認証キー取得 */
    $_getCdsApiKey() {
      return new Promise((resolve, reject) => {
        // Mapの認証キーを取得
        this.$store
          .dispatch("getCdsApiKey")
          .then(() => {
            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    /* 外部API(GeospaceAPI)ユーザ取得 */
    $_getGeospaceApiUser() {
      return new Promise((resolve, reject) => {
        // 地番APIのユーザを取得
        this.$store
          .dispatch("getGeospaceApiUser")
          .then((user) => {
            this.appId = user;
            resolve();
          })
          .catch((e) => {
            console.log(e);
            reject();
          });
      });
    },
    /* Map読み込み */
    $_getMaps(authkey) {
      var level = 16.5; // Zoomレベル
      if (this.project.address_code.length != 11) level = 14.5;

      // 初期位置 - プロジェクトの住所から設定
      const lon = this.project.longitude;
      const lat = this.project.latitude;

      // OpenLayersマップ
      this.map = new Map({
        target: "map",
        view: new View({
          center: transform([lon, lat], WGS84, WEB_MERCATOR),
          zoom: level,
        }),
        interactions: new defaults({
          altShiftDragRotate: false,
          pinchRotate: false,
        }),
      });

      // 地図
      const road = this.$_getRoad(authkey);
      for (let i = 0, len = road.length; i < len; i++) {
        this.map.addLayer(road[i]);
      }

      // 地番
      const chiban = this.$_getChiban();
      for (let i = 0, len = chiban.length; i < len; i++) {
        this.map.addLayer(chiban[i]);
      }

      // 用途
      const youto = this.$_getYouto();
      for (let i = 0, len = youto.length; i < len; i++) {
        this.map.addLayer(youto[i]);
      }

      // 手動
      const drawLayer = this.$_setDrawLayer();
      this.map.addLayer(drawLayer);

      // クリックEvent
      var self = this;
      this.map.on("click", function (evt) {
        if (!self.isDraw) {
          var stringifyFunc = createStringXY(14); // 座標表示の小数点以下の桁数の初期値
          var result = stringifyFunc(
            transform(evt.coordinate, WEB_MERCATOR, WGS84)
          ).split(",");
          //console.log('現在のZoomLevel= ' + self.map.getView().getZoom());
          if (self.map.getView().getZoom() > 16.4125) {
            /* 地番逆引き検索 */
            self.$_getSelectedChiban(result[0], result[1], evt.target);
          }
        }
      });
    },
    /* 地図の取得 */
    $_getRoad(authKey) {
      /* CDS URL及び基本パラメータ */
      const geospaceUrl = "http://cds.geospace.jp/images/bing/";
      const bingParam = "{z}/{y}/{x}?key=" + authKey;

      /* 電子地図 */
      let roadTile = new Tile({
        title: "電子地図",
        type: "base",
        visible: true,
        source: new XYZ({
          url: geospaceUrl + "road/" + bingParam,
          crossOrigin: "Anonymous",
        }),
      });
      return [roadTile];
    },
    /* 地番の取得 */
    $_getChiban() {
      const geospaceUrl = "https://api-maptile.geospace.jp/chibanmap/";
      const bingParam = this.appId + "/{z}/{y}/{x}.png";

      let chibanTile = new Tile({
        title: "地番情報",
        type: "base",
        source: new XYZ({
          url: geospaceUrl + bingParam,
          crossOrigin: "Anonymous",
        }),
      });
      return [chibanTile];
    },
    /* 用途地域の取得 */
    $_getYouto() {
      const geospaceUrl = "https://fandp-s.geospace.services/wms/wms";
      //const geospaceUrl = "https://chibanmap.geospace.services/map_contents/";
      const param =
        "?LAYERS=youto_opacity&STYLES=&FORMAT=image/png&TRANSPARENT=true";
      this.youtoTile = new Tile({
        title: "用途地域",
        type: "base",
        visible: false,
        source: new TileWMS({
          url: geospaceUrl + param,
        }),
      });
      console.log(geospaceUrl + param);
      return [this.youtoTile];
    },
    /* データ設定 */
    $_setData() {
      this.changeYoutoVisible();
    },
    /* 手動レイヤの設定 */
    $_setDrawLayer() {
      this.drawSource = new VectorSource();
      let drawLayer = new Vector({
        title: "drawLayer",
        source: this.drawSource,
        style: vectorStyle,
      });
      return drawLayer;
    },
    /* 用途地域ON・OFF */
    changeYoutoVisible() {
      const youtoFlag = this.$store.state.youtoFlag;
      if (youtoFlag)
        // 用途表示フラグ = ON
        this.youtoTile.setVisible(true);
      // 用途表示フラグ = OFF
      else this.youtoTile.setVisible(false);
    },
    /* 手動入力開始 */
    clickDrawStart() {
      this.isDraw = true;
      this.draw = new Draw({
        source: this.drawSource,
        type: "Polygon",
      });
      this.map.addInteraction(this.draw);

      /* 編集モードON */
      this.modify = new Modify({
        source: this.drawSource,
        deleteCondition: (evt) => {
          return evt.type == "singleclick";
        },
      });
      this.map.addInteraction(this.modify);
    },
    /* 手動入力終了 */
    async clickDrawEnd() {
      this.$_deleteChiban(null);
      //const self = this
      this.isDraw = false;
      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.modify);

      // 描画レイヤーのfeature取得
      let features = [];
      this.map.getLayers().forEach((layer) => {
        if (layer.get("title") === "drawLayer") {
          features = layer.getSource().getFeatures();
          return false;
        }
      });

      for (let i = 0, len = features.length; i < len; i++) {
        const geometry = features[i].getGeometry();
        // 中心座標を取得
        const extent = features[i].getGeometry().getExtent();
        const coordinate = transform(getCenter(extent), WEB_MERCATOR, WGS84);
        // 面積を取得
        const siteArea = this.$_calcSiteArea(geometry);
        // ポリゴンを取得
        let cood = geometry.getCoordinates()[0];
        for (let j = 0, len2 = cood.length; j < len2; j++) {
          cood[j] = transform(cood[j], WEB_MERCATOR, WGS84);
        }
        const po = {
          type: "Polygon",
          coordinates: [cood],
        };
        const polygon = JSON.stringify(po);
        // 作成した敷地の住所を取得
        await this.$_getDrawnAddress(
          coordinate[0],
          coordinate[1],
          siteArea,
          polygon
        );
      }

      this.draw = null;
      this.modify = null;
    },
    /* 手動入力情報クリア */
    clickDeleteDrawArea() {
      this.$_deleteChiban(null);
      this.map.getLayers().forEach((item) => {
        if (item && item.get("title") === "drawLayer") {
          item.getSource().clear();
        }
      });
    },
    /* 地番逆引き検索 */
    $_getSelectedChiban(lon, lat) {
      this.$emit("set-errors", []);
      const payload = {
        lon: lon,
        lat: lat,
      };
      this.$store
        .dispatch("getParcelNo", payload)
        .then((resData) => {
          if (cmnIsBlank(resData)) {
            this.$emit("set-errors", ["地番情報が見つかりませんでした。"]);
            return;
          }

          // 選択地番レイヤー設定
          const chibanLayer = this.$_makeChibanLayer(resData);
          //console.log('chibanLayer', chibanLayer)
          this.$_applyChibanLayer(resData, chibanLayer);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 逆ジオコーディング検索 */
    async $_getDrawnAddress(lon, lat, siteArea, polygon) {
      this.$emit("set-errors", []);
      const payload = {
        lon: lon,
        lat: lat,
      };
      await this.$store
        .dispatch("getAddress", payload)
        .then((resData) => {
          const chibanData = this.$_convGeoAddressToChiban(resData, polygon);
          this.$_addChiban(chibanData, siteArea);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /* 地番レイヤー、地番情報の設定 */
    async $_applyChibanLayer(chibanData, chibanLayer) {
      const chibanKey = chibanData.chiban;
      // 重複チェック
      const isDuplicated = this.$_checkDuplicate(chibanKey, chibanLayer);
      // 選択地番・レイヤーの追加・削除
      if (!isDuplicated) {
        const siteArea = await this.$_addSelectedLayer(chibanKey, chibanLayer);
        this.$_addChiban(chibanData, siteArea);
        //this.selectYoutoFlag = true
      } else {
        await this.$_deleteSelectedLayer(chibanKey);
        this.$_deleteChiban(chibanKey);
      }
    },
    /* 重複チェック */
    $_checkDuplicate(chibanKey, layer) {
      const layerTitle = layer.get("title");
      let isDuplicated = false;
      this.map.getLayers().forEach((item) => {
        if (item && item.get("title") === layerTitle) {
          isDuplicated = true;
          return false;
        }
      });
      return isDuplicated;
    },
    // 選択地番レイヤー作成
    $_makeChibanLayer(resData) {
      const geojsonObject = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: JSON.parse(resData.polygon),
          },
        ],
      };
      const feature = new GeoJSON({
        featureProjection: WEB_MERCATOR,
      }).readFeatures(geojsonObject);
      const chibanKey = resData.chiban;
      const vectorLayer = new Vector({
        title: chibanKey,
        source: new VectorSource({
          features: feature,
        }),
        style: vectorStyle,
      });
      return vectorLayer;
    },
    /* 選択地番・レイヤーを追加 */
    async $_addSelectedLayer(chibanKey, layer) {
      const self = this;
      this.map.addLayer(layer);
      let siteArea = 0;
      this.map.getLayers().forEach((item) => {
        if (item && item.get("title") === chibanKey) {
          const features = item.getSource().getFeatures();
          siteArea = self.$_calcSiteArea(features[0].getGeometry());
        }
      });
      return siteArea;
    },
    /* 選択地番・レイヤーを削除 */
    async $_deleteSelectedLayer(chibanKey) {
      const self = this;
      this.map.getLayers().forEach((item) => {
        if (item && item.get("title") === chibanKey) {
          self.map.removeLayer(item);
        }
      });
    },
    /* 逆ジオコーディングの型を地番逆引検索の型に変換 */
    $_convGeoAddressToChiban(geoAddress, polygon) {
      return {
        pref_name: geoAddress.pref_name,
        city_name: geoAddress.city_name,
        ooaza_name: geoAddress.ooaza_name,
        aza_name: geoAddress.aza_name,
        chiban: null,
        address_chiban: geoAddress.addr,
        jis_code: null,
        ooaza_code: null,
        aza_code: null,
        adcd: geoAddress.adcd,
        mitei_f: null,
        chiban_yyyymm: null,
        lat: geoAddress.input_lat,
        lon: geoAddress.input_lon,
        polygon: polygon,
      };
    },
    /* 地番情報の追加 */
    $_addChiban(chibanData, siteArea) {
      this.gisInfos.push(chibanData);
      this.parcelNoList.push({
        chibanKey: chibanData.chiban,
        parcelNoName: chibanData.address_chiban,
        siteArea: siteArea,
        addressCode: chibanData.adcd,
      });
      // console.log('parcelNoList', this.parcelNoList)
    },
    /* 地番情報の削除 */
    $_deleteChiban(chibanKey) {
      this.gisInfos = this.gisInfos.filter((item) => {
        return item.chiban !== chibanKey;
      });
      this.parcelNoList = this.parcelNoList.filter((item) => {
        return item.chibanKey !== chibanKey;
      });
    },
    /* 面積の計算 */
    $_calcSiteArea(geometry) {
      return cmnDecimalFormat(getArea(geometry, { radius: 6378137 }), 2);
    },
    /* 選択用途地域の分類取得 */
    $_getYoutoPropName(usageNo) {
      if (
        (usageNo >= USAGE_NO.DAI1_TEISO_JUKYO10 &&
          usageNo <= USAGE_NO.JUN_JUKYO) ||
        (usageNo >= USAGE_NO.DENEN_JUKYO10 && usageNo <= USAGE_NO.DENEN_JUKYO12)
      )
        return "Jukyo";
      else if (usageNo >= USAGE_NO.KINRIN_SHOGYO && usageNo <= USAGE_NO.SHOGYO)
        return "Shogyo";
      else if (usageNo >= USAGE_NO.JUN_KOGYO && usageNo <= USAGE_NO.KOGYO_SENYO)
        return "Kogyo";
      else return "";
    },
    /* 敷地情報登録 */
    buildAroundMap() {
      // 選択敷地がない場合は終了
      if (this.gisInfos.length === 0) return;

      const zoom_level = cmnExRound(this.map.getView().getZoom());
      const center_coordinate = transform(
        this.map.getView().getCenter(),
        WEB_MERCATOR,
        WGS84
      );
      let params = this.$store.state.params;
      if (this.project.site_planning === null)
        this.project.site_planning = new initSitePlanning();
      this.project.site_planning.center_latitude = center_coordinate[1];
      this.project.site_planning.center_longitude = center_coordinate[0];
      this.project.site_planning.zoom_level = zoom_level;
      params.data = this.project;
      this.$store.commit("setParams", params);

      const payload = {
        data: params.data,
        gis_info: this.gisInfos,
      };
      this.$refs.modalBlockMessage.init();
      this.$store
        .dispatch("buildAroundMap", payload)
        .then(() => {
          this.$store.dispatch("updateProjectUpdateDate");
          this.$store.dispatch("updateUsersUsageTimes");
          this.$refs.modalBlockMessage.close();
          this.$_openModalInputRoadAndEntrance();
        })
        .catch(() => {
          this.$refs.modalBlockMessage.close();
        });
    },
    /* 接道と出入口登録を開く */
    $_openModalInputRoadAndEntrance() {
      this.$refs.modalInputRoadAndEntrance.init();
    },
    /* 次ページ遷移 */
    nextPage() {
      this.$router.push({ path: "road-and-entrance" });
    },
  },
};

// ポリゴンのスタイル
const vectorStyle = [
  new Style({
    stroke: new Stroke({
      color: "rgba(65, 150, 0, 1.0)",
      width: 3,
    }),
    fill: new Fill({
      color: "rgba(0, 20, 255, 0.4)",
    }),
  }),
  new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: "rgba(230, 230, 230, 1)",
      }),
      stroke: new Stroke({
        color: "black",
        width: 1,
      }),
    }),
  }),
];
</script>

<style lang="scss" scoped>
.my-building-image {
  position: absolute;
  top: 0px;
  left: 35px;
  width: 70px;
  height: 70px;
}
</style>